"use strict";

const body = document.body;
const defaultConfig = {
  // class of the parent element where the error/success class is added
  classTo: "form-group",
  errorClass: "has-danger",
  successClass: "has-success",
  // class of the parent element where error text element is appended
  errorTextParent: "form-group",
  // type of element to create for the error text
  errorTextTag: "div",
  // class of the error text element
  errorTextClass: "text-help",
};

// Smooth scrolling
const logoHeader = document.querySelector(".header__logo");
const headingPrimary = document.querySelector(".header-text-wrapper__title");

const btnConnect = document.querySelector("#btn-connect");
const btnGoto = document.querySelector("#btn-goto");

[btnConnect, btnGoto].forEach((btn) => {
  btn.addEventListener("click", () => {
    if (screen.width > 970) logoHeader.scrollIntoView({ behavior: "smooth" });
    else headingPrimary.scrollIntoView({ behavior: "smooth" });
  });
});

// Popups data
const successPopup = document.getElementById("success");
const errorPopup = document.getElementById("error");
const duplicationPopup = document.getElementById("duplication");

function popupPositionHandler() {
  document.body.classList.remove("lock");
  body.style.top = "";
  window.scrollTo(0, body.dataset.scroll);
  body.removeAttribute("data-scroll");
}

[successPopup, errorPopup, duplicationPopup].forEach((elem) => {
  elem.addEventListener("click", () => {
    popupPositionHandler();

    switch (elem.id) {
      case "success":
        successPopup.classList.add("invisible");
        break;
      case "error":
        errorPopup.classList.add("invisible");
        break;
      case "duplication":
        duplicationPopup.classList.add("invisible");
        break;
    }
  });
});

const closeButton = document.querySelector(".popup__close-btn");

const popupWrapper = document.querySelector(".popup__wrapper");
popupWrapper.addEventListener("click", (e) => {
  e.stopPropagation();

  if (e.target.closest(".popup__close-btn")) {
    popupPositionHandler();

    switch (e.currentTarget.id) {
      case "success":
        successPopup.classList.add("invisible");
        break;
      case "error":
        errorPopup.classList.add("invisible");
        break;
      case "duplication":
        duplicationPopup.classList.add("invisible");
        break;
    }
  }
});

// form data
const phoneInputs = document.querySelectorAll('input[data-form="phone"]')
const forms = document.querySelectorAll('form[data-form]')

forms.forEach(form => {
  const pristineHeaderForm = new Pristine(form, defaultConfig);

  form.addEventListener("submit", async (event) => {
    event.preventDefault();
  
    const isValid = pristineHeaderForm.validate();

    if (!isValid) return;
  
    const height = window.scrollY;
    body.dataset.scroll = height;
  
    body.classList.add("lock");
    body.style.top = `-${height}px`;
  
    const isAmoSended = await sendToAmo(form)

    sendToDB(form, isAmoSended)

    form.reset()
  });
})

phoneInputs.forEach(input => {
  input.addEventListener('input', inputValue)
  Maska.create(input, {
    mask: "+7 (###) ###-##-##",
  });
})

function inputValue() {
  if (this.value.substr(0, 1) === "8" || this.value.substr(0, 1) === "7") {
    this.value = this.value.replace(this.value.substr(0, 1), "+7 (");
  }
}

function afterSuccessSend(form) {
  form.submit.disabled = false;
  form.submit.innerHTML = `Отправить заявку`;
  successPopup.classList.remove("invisible");
}

function afterFailSend(form) {
  form.submit.disabled = false;
  form.submit.innerHTML = `Отправить заявку`;
  errorPopup.classList.remove("invisible");
}

function prepareToSendingForm(form) {
  form.submit.disabled = true;
  form.submit.innerHTML = `Отправляется <span class="submit-spinner"></span>`;
}

async function sendToAmo(form) {
  prepareToSendingForm(form)

  let isAmoSended
  const amoData = new FormData(form)
  
  const url = new URL(location.href)
  const needToAdd = ['utm_medium', 'utm_campaign', 'utm_source']
  
  needToAdd.forEach(param => {
    if(url.searchParams.has(param)) {
      amoData.append(param, url.searchParams.get(param))
    }
  })

  const request = await fetch('/amo/request.php', {
    method: 'post', 
    body: amoData
  })
 
  const response = await request.text()
  
  if(response === 'success') {
    afterSuccessSend(form)
    isAmoSended = 'yes'
  } else {
    afterFailSend(form)
    isAmoSended = 'no'
  }

  return isAmoSended
}

async function sendToDB(form, isAmoSended) {
  const data = new FormData(form)
  data.append('is_sending_to_amo', isAmoSended)

  const request = await fetch('/actions/form_handler.php', {
    method: 'post',
    body: data
  })

  const response = await request.text()

  console.log(response)
}


